import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { IA, ImageWrapper } from '@/ImageArticleBorder';
import { Statement } from '@/Statement';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const AboutUs = () => {
    return (
        <Fragment>
            <CommonHero>
                <StaticImage
                    src="../../assets/images/doctor-family.jpg"
                    alt="doctor joshua lovelace"
                    loading="eager"
                    objectPosition="top center"
                />
            </CommonHero>
            <Statement />
            <IA right>
                <ImageWrapper>
                    <StaticImage
                        src="../../assets/images/doctor.jpg"
                        alt="doctor joshua lovelace"
                        width={528}
                        height={720}
                    />
                </ImageWrapper>

                <div>
                    <h2>Dr joshua r lovelace</h2>

                    <p>
                        Dr. Joshua Lovelace grew up in Jupiter, Florida where going to the beach or
                        fishing on a boat was mandatory almost every weekend. So, it was an easy
                        transition to the Nocatee area and all its outdoor family fun. He enjoys
                        spending his weekends now at the Spray or Splash parks and countless other
                        activities with his wife, Alexis, and two daughters, Mackenzie and Reese.
                        The Nocatee community welcomed the Lovelace family in 2020 and inspired them
                        to open a family centered practice right in the heart of Nocatee. They
                        strive to give patients that same welcoming feeling they continue to receive
                        from residents in this great community.
                    </p>
                </div>
            </IA>
            <Article>
                <h2>Education and more</h2>
                <p>
                    Dr. Lovelace graduated from the University of Florida College of Dentistry after
                    completing his master’s degree at Florida Atlantic University. He loved being
                    able to return to Gainesville and continue to gator chomp at Ben Hill Griffin
                    stadium after finishing his undergraduate degree in biology there. GO GATORS!{' '}
                </p>

                <p>
                    He would like to extend his warmest welcome to all ages to come and be a part of
                    the Lovelace family. He focuses on General Dentistry and covers your dental
                    needs with an emphasis on treating the whole family with comprehensive care. Our
                    team works hard to make you feel like you found your forever dental home!{' '}
                </p>
            </Article>
        </Fragment>
    );
};

export default AboutUs;
